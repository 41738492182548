/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */ 

import React from "react"
import { connect } from 'react-redux';
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceRoute/HomePage/HomePage.container';
import CmsBlock from 'Component/CmsBlock';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Footer from 'Component/Footer';
import MobileSearchField from 'Component/MobileSearchField';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import { toggleBreadcrumbs } from 'Store/Breadcrumbs/Breadcrumbs.action';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import './HomePage.override.style';
import CmsPage from 'Route/CmsPage';

/** @namespace Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
});

/** @namespace Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleBreadcrumbs: (isVisible) => dispatch(toggleBreadcrumbs(isVisible))
});

/** @namespace Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    
    state = {
        isInstallPromptAvailable: window.isInstallPromptAvailable,
        isDescriptionExpanded: false
    };

    toggleDescription = () => {
        this.setState({ isDescriptionExpanded: !this.state.isDescriptionExpanded });
    }

    componentDidMount() {
        super.componentDidMount();
        
        const { toggleBreadcrumbs } = this.props;
        toggleBreadcrumbs(false);
    }

    
    render() {
        const { isInstallPromptAvailable, isDescriptionExpanded } = this.state;

        const descriptionExpandedActive = isDescriptionExpanded ? 'active' : '';

        return (
            <div block="HomePage" mods={ { mobile: !!isInstallPromptAvailable } }>
                <main block="CmsPage">
                    <div block="CmsPage" elem="Content">
                        {/* { this.renderInstallPrompt() } */}                            
                        <MobileSearchField />
                    
                        <CmsBlock identifier={`top-offer-banner`}  placeHolderSize={{width:'100%', height:'333px'}}  placeHolderMobileSize={{width:'100%', height:'120px'}} />
                    
                        <CmsBlock identifier='home-herobanner'  />
                    
                        <CmsBlock identifier='category-gallery' />
                      
                        <RenderWhenVisible>
                            <CmsBlock identifier='featured-products' />
                        </RenderWhenVisible>
                        <div block="hm-brands" elem="sect" mix={{ block:"page-section"}}>
                            <div block="ContentWrapper">
                                <div block="page-section" elem="head">
                                    <h2 block="page-section" elem="title">{ __('SHOP THE BEST BRANDS') }</h2>
                                </div>
                                <div block="page-section" elem="body">
                                    <CmsBlock identifier='best-brands' />
                                </div>
                            </div>
                        </div>
                        <RenderWhenVisible>
                            <CmsBlock identifier='full-offer-banner' />
                        </RenderWhenVisible>
                        <div block="hm-trend-cat" elem="sect" mix={{ block:"page-section"}}>
                            <div block="ContentWrapper">
                                <div block="page-section" elem="head">
                                    <h2 block="page-section" elem="title">{ __('Trending Categories') }</h2>
                                </div>
                                <div block="page-section" elem="body">
                                    <CmsBlock identifier='trending-categories' />
                                </div>
                            </div>
                        </div>
                        <RenderWhenVisible>
                            <CmsBlock identifier='new-arrivals' />
                        </RenderWhenVisible>
                        <RenderWhenVisible>
                            <CmsBlock identifier='home-gallery' />
                            <div block="hm-descr" elem="sect" mix={{ block:"page-section"}}>
                                <div block="ContentWrapper">
                                    <div block="hm-descr" elem="body" mix={{block:descriptionExpandedActive}}>
                                        <CmsBlock identifier='home-bottom-description' />
                                        <div block="hm-descr" elem="readmore" >
                                            <div block="hm-descr" elem="readmorebtn" onClick={this.toggleDescription} >
                                                {descriptionExpandedActive ? __('Read Less') : __('Read More') } <ChevronIcon direction={ Directions.RIGHT } />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </RenderWhenVisible>
                    </div>
                </main>
                <div block="CmsPage" elem="HomeWrapper">
                    <CmsPage { ...this.containerProps() } />
                </div>
                <Footer isVisibleOnMobile />
            </div>
        );
    }
   
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
